<template>
	<div class="site-wrapper" :class="{ 'site-sidebar--fold': sidebarFold }" v-loading.fullscreen.lock="loading"
		element-loading-text="拼命加载中">
		<template v-if="!loading">
			<nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
				<div class="site-navbar__header">
					<h1 class="site-navbar__brand" @click="$router.push({ name: 'home' })">
						<a class="site-navbar__brand-lg" href="javascript:;">
							<img src="@/assets/images/logo02.png" style="height: 30px;">
						</a>
						<a class="site-navbar__brand-mini" href="javascript:;">
							<img src="@/assets/images/logoMini02.png" style="height: 30px;" />
						</a>
					</h1>
				</div>
				<div class="site-navbar__body clearfix">
					<el-menu class="site-navbar__menu"
							mode="horizontal"
							default-active="0"
							style="margin-left: 10px;">
							<el-menu-item index="0">
								查看完工资料
							</el-menu-item>
					</el-menu>
					<el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal">
						<el-menu-item class="back-list">
							<img src="@/assets/images/backIcon.png" alt="">
							返回
						</el-menu-item>
					</el-menu>
				</div>
			</nav>
			<div class="site-content__wrapper"
				:style="{ 'min-height': documentClientHeight + 'px','margin-left':'0px','padding':'80px 30px 30px 30px' }">
				<div class="mod-config">
					<el-card body-style="padding-bottom: 0;">
						<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
							<el-row>
								<el-col :span="20">
									<el-form-item label="标的物">
										<el-select class="selItemInput" v-model="subjectNo" placeholder="请选择">
                                            <el-option
                                                    v-for="item in subjectList"
                                                    :key="item.displayValue"
                                                    :label="item.displayName"
                                                    :value="item.displayValue">
                                            </el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="资料种类" v-if="tabIndex != 7">
										<el-select class="selItemInput" v-model="fileKind" placeholder="请选择">
											<el-option v-for="item in fileKindList" :key="item.displayValue"
												:label="item.displayName" :value="item.displayValue">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="4" class="selBtnGroup">
									<el-button class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
									<el-button class="primaryPlainBtn" type="primary" @click="selGetDataList()">检索</el-button>
								</el-col>
							</el-row>
						</el-form>
					</el-card>
					<el-row style="margin-top: 20px;">
						<el-col :span="12" :offset="12" style="text-align: right;">
								<el-pagination
									background
									@current-change="currentChangeHandle"
									layout="prev, pager, next"
									:current-page="pageIndex"
									:pager-count="5"
									:page-size="pageSize"
									:total="totalPage">
								</el-pagination>
						</el-col>
					</el-row>
					<div :style="siteContentViewHeight()">
					  <el-card
						style="margin-top: 20px; height: 100%;"
						body-style="height: 100%; padding: 0;">
						  <el-row style="height: 100%;">
							  <el-col :span="24" style="height: 100%;">
								  <el-table
								   ref="dataListTable"
									class="dataListTable"
									:data="dataList"
									stripe
									header-align="center"
									style="width: 100%;"
									header-cell-class-name="dataListTHeader">
									<el-table-column
										type="index"
										width="60"
										align="center">
									</el-table-column>
									<el-table-column
									  prop="fileKindName"
									  label="资料种类"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="fileName"
									  label="资料名称"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="updDate"
									  label="最后修改时间"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="editCopies"
									  label="编辑版"
									  align="center">
									  <template slot-scope="scope">
										<span style="color: #00428e;" @click="" v-if="scope.row.editCopies>0">{{scope.row.editCopies}}份</span>
										<span v-else>不适用</span>
									  </template>
									</el-table-column>
									<el-table-column
									  prop="scanCopies"
									  label="扫描签字版"
									  align="center">
									  <template slot-scope="scope">
										<span style="color: #00428e;" @click="" v-if="scope.row.scanCopies>0">{{scope.row.scanCopies}}份</span>
										<span v-else>不适用</span>
									  </template>
									</el-table-column>
									<el-table-column
									  prop="paperCopies"
									  label="纸质版"
									  align="center">
                                        <template slot-scope="scope">
                                            <span style="color: #00428e;" v-if="scope.row.paperCopies>0">{{scope.row.paperCopies}}份</span>
                                            <span v-else>不适用</span>
                                        </template>
									</el-table-column>
								  </el-table>
							  </el-col>
						  </el-row>
					  </el-card>
					</div>
				</div>
			</div>
		</template>
	</div>

</template>

<script>
	import $common from '@/utils/common.js'
	export default {
		// provide() {
		// 	return {
		// 		// 刷新
		// 		refresh() {
		// 			this.$store.commit('common/updateContentIsNeedRefresh', true)
		// 			this.$nextTick(() => {
		// 				this.$store.commit('common/updateContentIsNeedRefresh', false)
		// 			})
		// 		}
		// 	}
		// },
		data() {
			return {
			    workNo: '',
                subjectNo: '',
                fileKind: '',
                subjectList:[],
                fileKindList: [],
				tabIndex: "0",
				loading: true,
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 10,
				//总页数
				totalPage: 0,
                dataList: []
			}
		},
		components: {},
		computed: {
			documentClientHeight: {
				get() {
					return this.$store.state.common.documentClientHeight
				},
				set(val) {
					this.$store.commit('common/updateDocumentClientHeight', val)
				}
			},
			sidebarFold: {
				get() {
					return this.$store.state.common.sidebarFold
				}
			},
			navbarLayoutType: {
				get() {
					return this.$store.state.common.navbarLayoutType
				}
			},
		},
		created() {
			// this.getUserInfo()
			this.loading = false
		},
		mounted() {
		    this.workNo = this.$route.query.workNo;
			this.resetDocumentClientHeight();
            this.getSubjectDropDown();
		},
		methods: {
            getFinishedFile(){
                this.$http({
                    url:  this.$store.state.httpUrl + "/common/getFinishedFile?workNo=" + this.workNo+"&subjectNo="+this.subjectNo,
                    method: "get",
                    params: {},
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.fileKindList = data.body;
                    }
                });
            },
            resetFormData(){
                this.fileKind = '';
                this.getSubjectDropDown();
            },
            selGetDataList(){
                this.page = 1;
                this.getDataList();
            },
            getSubjectDropDown(){
                this.$http({
                    url:  this.$store.state.httpUrl + "/common/getSubjectDropDownByOut/" + this.workNo,
                    method: "get",
                    params: {},
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.subjectList = data.body;
                        if (this.subjectList){
                            this.subjectNo = this.subjectList[0].displayValue;
                        }
                        this.getFinishedFile();
                        this.getDataList();
                    }
                });
            },
            getDataList(){
                this.$http({
                    url:  this.$store.state.httpUrl + "/business/archivefileinfo/listByFinishedFile" ,
                    method: "get",
                    params: {
                        limit: this.pageSize,
                        page: this.pageIndex,
                        fileKind: this.fileKind?this.fileKind:'',
                        subjectNo: this.subjectNo,
                        workNo: this.workNo
                    },
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.dataList = data.body.records;
                        this.totalPage = data.body.totalCount;
                    }
                });
            },
			// 重置窗口可视高度
			resetDocumentClientHeight() {
				this.documentClientHeight = document.documentElement['clientHeight']
				window.onresize = () => {
					this.documentClientHeight = document.documentElement['clientHeight']
				}
			},
			siteContentViewHeight () {
			  var height = this.$store.state.common.documentClientHeight - 280;
			  return { height: height + 'px' }
			},
			setTabIndex(index){
				this.tabIndex = index;
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
		}
	}
</script>

<style>
</style>
